<!-- @format -->

<template>
  <div>
    <div class="filmwelt-footer" :class="$mq">
      <a class="external" href="http://www.filmweltverleih.de/" target="_blank"
        >FILMWELT HOME</a
      >
      <a @click.prevent="emitPressClick" href="#">Presse</a>
      <a
        class="external"
        href="http://www.filmweltverleih.de/page/impressum"
        target="_blank"
        >Impressum</a
      >
      <a
        class="external"
        href="http://www.filmweltverleih.de/page/datenschutz"
        target="_blank"
        >Datenschutz</a
      >
    </div>
    <div class="filmwelt-cline" :class="$mq">
      © 2020 Filmwelt Verleihagentur GmbH. Alle Rechte vorbehalten. made by
      <a
        class="text-white external"
        href="http://www.novagraphix.de"
        target="_blank"
        >novagraphix</a
      >
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    props: {
      teilnahmebedingung: {
        type: String,
        default: 'leer',
      },
    },
    methods: {
      emitPressClick() {
        EventBus.$emit('pressmodal')
      },
    },
  }
</script>

<style lang="scss">
  /** @format */

  .filmwelt-footer {
    $self: &;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.2rem;
    font-size: 17px;
    justify-content: center;
    text-transform: uppercase;
    a {
      font-weight: 900;
      color: black;
      outline: none;
      &:hover {
        color: lighten(white, 10%);
      }
      &:not(:last-child):after {
        color: black;
        content: '|';
        padding: 0 5px 0 5px;
      }
    }
    &.md {
      a {
        font-size: 22px;
        color: black;
        &:not(:last-child):after {
          color: black;
          content: '|';
        }
        &:last-child {
          margin-left: 0;
        }
        &:hover {
          color: white;
        }
      }
    }
    &.sm {
      a {
        color: black;
        &:after {
          content: '';
        }
        &:last-child {
          margin-left: 0;
        }
        &:hover {
          color: white;
        }
      }
    }
    &.md,
    &.lg,
    &.xl,
    &.xxl {
      flex-direction: row;
    }
  }
  .filmwelt-billing {
    width: 100%;
    &.lg,
    &.xl,
    &.xxl {
      margin-bottom: 0.5rem;
      padding: 0 5rem;
    }
    img {
      margin: 0 auto;
      padding: 0 5rem;
      &.sm,
      &.md {
        margin-top: 1rem;
        width: 100%;
        height: auto;
      }
      &.lg,
      &.xl,
      &.xxl {
        width: auto;
      }
    }
  }
  .filmwelt-cline {
    position: relative;
    font-size: 12px;
    color: black;
    padding: 0 1rem;
    &.sm {
      margin-top: 1rem;
      font-size: 10px;
    }
    a {
      color: black;
      &:hover {
        color: white;
      }
    }
  }
</style>
